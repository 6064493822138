import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { redeemModalHide } from "../../redux/counterSlice";
import { useWalletData } from "../WalletDataContext";
import { collectLoot, collectLootLockFee } from "../../utils/radixDT";
import ArtData from "../../data/art_data";

const RedeemModal = () => {
  const redeemModal = useSelector((state) => state.counter.redeemModal);
  const dispatch = useDispatch();
  const { walletData, revealedUnredeemedTickets, fetchWalletData } = useWalletData();
  const [selectedTicketIds, setSelectedTicketIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showRedeemSuccess, setShowRedeemSuccess] = useState(false);
  const [richUnredeemedTickets, setRichUnredeemedTickets] = useState([]);
  const [ticketAddress, setTicketAddress] = useState(null);

  useEffect(() => {
    console.log("SESSION STORAGE", sessionStorage.getItem('lootboxComponentAddress')) // Check if this is set
    let tickAddr = ''
    if (sessionStorage.getItem('lootboxComponentAddress') === null || sessionStorage.getItem('lootboxComponentAddress') === undefined) {
      tickAddr = ArtData.find(box => box.componentAddress === process.env.NEXT_PUBLIC_FEATURED_COMPONENT_ADDRESS).ticketAddress;
    } else {
      tickAddr = ArtData.find(box => box.componentAddress === sessionStorage.getItem('lootboxComponentAddress'))?.ticketAddress;
    }
    setTicketAddress(tickAddr);
  }, []);

  useEffect(() => {
    const idsOfUnredeemed = revealedUnredeemedTickets?.address;
    console.log("ticket address passed to post reveal", ticketAddress);

    if (Array.isArray(idsOfUnredeemed) && idsOfUnredeemed.length) {
      (async () => {
        return await fetch('/api/ticket-info-post-reveal', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            resource_address: ticketAddress,
            non_fungible_ids: idsOfUnredeemed
          }),
        }).then(async (res) => {
          const idResult = await res.json();
          setRichUnredeemedTickets(idResult.address);
          console.log("idResult", idResult.address);
          if (selectedTicketIds.length === 0) {
            setSelectedTicketIds([idResult.address[0].non_fungible_id]);
          }
        });
      })();
    }
  }, [revealedUnredeemedTickets]);

  const handleRedeemTicket = async () => {
    setLoading(true);
    const accountAddress = walletData.accounts[0].address;
    try {
      console.log("Collecting loot with account address", accountAddress);
      const isBobby100ClubMember = await fetch('/api/bobby-100-club', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ account_address: accountAddress, token_amount: 0 })
      }).then(res => res.json());

      if (isBobby100ClubMember.result) {
        await collectLootLockFee(accountAddress, selectedTicketIds, ticketAddress, sessionStorage.getItem('lootboxComponentAddress'));
      } else {
        await collectLoot(walletData.accounts[0].address, selectedTicketIds, ticketAddress, sessionStorage.getItem('lootboxComponentAddress'));
      }

      await fetchWalletData();
      setShowRedeemSuccess(true);
      setTimeout(() => {
        setShowRedeemSuccess(false);
      }, 2000);
      setSelectedTicketIds(revealedUnredeemedTickets?.address?.map(ticket => ticket.non_fungible_id) || []);
      if (revealedUnredeemedTickets?.address?.length === 0) {
        dispatch(redeemModalHide());
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const toggleTicketSelection = (ticketId) => {
    setSelectedTicketIds(prevSelected => {
      if (prevSelected.includes(ticketId)) {
        return prevSelected.filter(id => id !== ticketId);
      } else {
        return [...prevSelected, ticketId];
      }
    });
  };

  return (
    <div className="">

      {/* redeem success */}
      {showRedeemSuccess && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 p-8 rounded-lg items-center justify-center flex flex-col">
          <div className="p-4 bg-accent-dark/80 flex flex-col gap-4 rounded-lg shadow items-center">
            <div className="bg-accent-dark text-white text-xl p-4 font-extrabold tracking-wider rounded-md shadow">
              Tickets Redeemed Successfully
            </div>
          </div>
        </div>
      )}

      {/* <!-- Wallet Modal --> */}
      <div
        className={redeemModal ? "fixed top-0 z-20 w-full max-w-full h-screen fade show  max-h-screen overflow-hidden flex flex-col items-center justify-center" : "modal fade hidden"}
      >    
        {/* loading spinner */}
        {loading && (
          <div className="fixed top-0 left-0 w-full h-full bg-black z-50 p-8 bg- rounded-lg items-center justify-center flex flex-col">
              <div className="bg-accent-dark dark:accent-accent-light text-white text-xl p-4 font-extrabold  rounded-md shadow">
                <p className="animate-pulse">Check your wallet to complete the transaction</p>
            </div>
          </div>
        )}

        <div className="modal-dialog backdrop-blur-md overflow-auto overflow-x-hidden bg-white/40 dark:bg-jacarta-500/40 rounded-xl py-4 xl:py-16 min-h-fit min-w-[80%] container font-display text-xs flex flex-col lg:flex-row items-center relative z-30 shadow-lg dark:shadow-white/30">
          
          {/* ticket select */}
          <div className="modal-content w-full lg:w-1/3">
         
            <div className="modal-header">
              <h5 className="modal-title font-display text-sm" id="walletModalLabel">
                Redeem your tickets
              </h5>   
              <button
                type="button"
                className="block lg:hidden btn-close absolute top-0 mt-4 -mr-4"
                onClick={() => dispatch(redeemModalHide())}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                </svg>
              </button>
            </div>

            {/* <!-- Body --> */}
            <div className="modal-body p-2 text-center w-full">
              <div className="text-center dark:text-white h-48 overflow-auto rounded-md py-4 px-2 w-full">
                {revealedUnredeemedTickets?.address?.length > 0 ? (
                  richUnredeemedTickets?.map((ticket, k) => {
                    return (
                      <div
                        key={k}
                        className={`flex flex-col items-center justify-center space-y-4 py-4 rounded-lg my-2 bg-gradient-to-br from-accent-dark to-gray shadow-md hover:bg-accent-light transition-colors cursor-pointer ${selectedTicketIds.includes(ticket.non_fungible_id) ? "bg-accent-light" : ""}`}
                        onClick={() => toggleTicketSelection(ticket.non_fungible_id)}
                      >
                        <div className="flex flex-col items-center justify-center space-y-2">
                          <div className="flex flex-col items-center justify-center space-y-2 text-sm xl:text-md">
                            Ticket: {ticket.non_fungible_id}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  ''
                )}
              </div>
            </div>
            {/* <!-- end body --> */}

            <div className="modal-footer">
              <div className="flex items-center justify-center space-x-4">
                <button
                  rel="noreferrer noopener"
                  className="bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all text-mf hover:scale-105"
                  onClick={handleRedeemTicket}
                >
                  Redeem Tickets
                </button>
              </div>
            </div>
          </div>
          
          {/* ticket view */}
          <div className="modal-content w-full">
            {/* <!-- Body --> */}
            <div className="modal-body h-[32rem] p-4 text-center w-full">
              <span className="text-sm font-semibold ">
                Selected Tickets: {selectedTicketIds.join(", ")}
              </span>
              <div className="text-center dark:text-white h-full overflow-auto rounded-md py-4 px-2 w-full">
                {revealedUnredeemedTickets?.address?.length > 0 ? 
                  (richUnredeemedTickets?.filter(ticket => selectedTicketIds.includes(ticket.non_fungible_id)).map((ticket, i) => {
                    return (
                      <div key={i} className="flex flex-col lg:flex-row gap-2 w-full items-center justify-center  p-4 bg-gray-100 ">
                        {ticket.loots.map((loot, j) => loot && (
                          <div key={j} className="shadow-lg rounded-lg overflow-hidden p-4 border-2 shadow w-full">
                            <img className="w-full h-64 object-cover" src={loot.nonFungibleLocalId[0]?.key_image_url || loot.icon_url || 'https://assets-global.website-files.com/6053f7fca5bf627283b582c2/6460ea3385d0ef5c36da40ae_icon-fs-radix.png'} alt={loot.nonFungibleLocalId[0]?.id || 'Default'} />-
                            <h2 className="text-sm font-bold mt-4">{loot.nonFungibleLocalId[0]?.id || ''}</h2>
                            {/* slice the token resource address */}
                            <p className="text-gray-600 mt-2 truncate" title={loot.resourceAddress}>
                              {loot.resourceAddress.length > 20
                                ? `${loot.resourceAddress.substring(0, 5)}...${loot.resourceAddress.substring(loot.resourceAddress.length - 5)}`
                                : loot.resourceAddress}
                            </p>

                            <p className="text-gray-500 text-lg mt-2">{loot.fieldName}</p>
                          </div>
                        ))}
                      </div>
                    )
                  })) : ('')
                }
              </div>
              <button
                type="button"
                className="hidden lg:block btn-close absolute -top-16 -right-4 m-5 z-50"
                onClick={() => dispatch(redeemModalHide())}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                </svg>
              </button>
            </div>
            {/* <!-- end body --> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedeemModal;
