import React, { createContext, useState, useCallback, useContext, useEffect } from "react";
import ArtData from "../data/art_data";

const WalletDataContext = createContext();

export const WalletDataProvider = ({ children, rdt }) => {
  const [walletData, setWalletData] = useState(null);
  const [accountFungibles, setAccountFungibles] = useState(null);
  const [accountNonFungibles, setAccountNonFungibles] = useState(null);
  const [unrevealedTickets, setUnrevealedTickets] = useState(null);
  const [revealedUnredeemedTickets, setRevealedUnredeemedTickets] = useState(null);

  const fetchFungibles = useCallback(async (currentWalletData) => {
    if (!currentWalletData?.accounts?.length) return;
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          address: currentWalletData.accounts[0].address,
        }),
      };
      const response = await fetch(`${process.env.NEXT_PUBLIC_MVP_API_URL}/state/entity/page/fungibles/`, requestOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("There was an error fetching the fungibles data", error);
    }
  }, []);

  const fetchNonFungibles = useCallback(async (currentWalletData) => {
    if (!currentWalletData?.accounts?.length) return;

    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          address: currentWalletData.accounts[0].address,
        }),
      };

      const response = await fetch(`${process.env.NEXT_PUBLIC_MVP_API_URL}/state/entity/page/non-fungibles/`, requestOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("There was an error fetching the non-fungibles data", error);
    }
  }, []);

  const fetchUnrevealedTickets = useCallback(async (newWalletData) => {
    if (!newWalletData?.accounts?.length) return;
    console.log("fetching unrevealed tickets")
    try {
      const ticketAddress = ArtData.find(box => box.componentAddress === sessionStorage.getItem('lootboxComponentAddress')).ticketAddress;
      console.log("ticket address", ticketAddress)
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          account_address: newWalletData.accounts[0].address,
          ticket_address: ticketAddress
        }),
      };

      const response = await fetch('/api/all-account-tickets-unrevealed', requestOptions);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setUnrevealedTickets(data);
      return data;
    } catch (error) {
      console.error("There was an error fetching the fungibles data", error);
    }
  });

  const fetchRevealedUnredeemedTickets = useCallback(async (newWalletData) => {
    if (!newWalletData?.accounts?.length) return;
    const ticketAddress = ArtData.find(box => box.componentAddress === sessionStorage.getItem('lootboxComponentAddress')).ticketAddress;
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          account_address: newWalletData.accounts[0].address,
          ticket_address: ticketAddress
        }),
      };

      const response = await fetch('/api/all-account-tickets-revealed-unredeemed', requestOptions);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setRevealedUnredeemedTickets(data);
      return data;
    } catch (error) {
      console.error("There was an error fetching the fungibles data", error);
    }
  });

  const fetchWalletData = useCallback(() => {
    let walletDataSubscription;
    let fungiblesSubscription;
    let nonFungiblesSubscription;
    let unrevealedTicketsSubscription;
    let revealedUnredeemedTicketsSubscription;
    

    if (!rdt) return; // Make sure rdt is available

    try {
      walletDataSubscription = rdt.walletApi.walletData$.subscribe(newWalletData => {
        setWalletData(newWalletData);

        fungiblesSubscription = fetchFungibles(newWalletData).then(fungibles => {
          setAccountFungibles(fungibles);
        }).catch(error => {
          console.error("There was an error fetching the fungibles data", error);
        });

        nonFungiblesSubscription = fetchNonFungibles(newWalletData).then(nonFungibles => {
          setAccountNonFungibles(nonFungibles);
        }).catch(error => {
          console.error("There was an error fetching the non-fungibles data", error);
        });

        unrevealedTicketsSubscription = fetchUnrevealedTickets(newWalletData).then(unrevealedTickets => {
          setUnrevealedTickets(unrevealedTickets);
        }).catch(error => {
          console.error("There was an error fetching the non-fungibles data", error);
        });

        revealedUnredeemedTicketsSubscription = fetchRevealedUnredeemedTickets(newWalletData).then(revealedUnredeemedTickets => {
          setRevealedUnredeemedTickets(revealedUnredeemedTickets);
        }).catch(error => {
          console.error("There was an error fetching the non-fungibles data", error);
        });
      });
    } catch (error) {
      console.error("There was an error fetching the wallet data", error);
    }

    return () => {
      if (walletDataSubscription) walletDataSubscription.unsubscribe();
      if (fungiblesSubscription) fungiblesSubscription.unsubscribe();
      if (nonFungiblesSubscription) nonFungiblesSubscription.unsubscribe();
      if (unrevealedTicketsSubscription) unrevealedTicketsSubscription.unsubscribe();
      if (revealedUnredeemedTicketsSubscription) revealedUnredeemedTicketsSubscription.unsubscribe();
    };
  }, [rdt]);
  
  useEffect(() => {
    fetchWalletData(); // Fetch data initially and whenever rdt changes
  }, [fetchWalletData, window.location.pathname]);

  return (
    <WalletDataContext.Provider value={{ walletData, accountFungibles, accountNonFungibles, fetchWalletData, unrevealedTickets, revealedUnredeemedTickets }}>
      {children}
    </WalletDataContext.Provider>
  );
};

// Custom hook that allows our components to easily use the context
export const useWalletData = () => {
  return useContext(WalletDataContext);
};
