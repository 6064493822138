import React, { createContext, useState, useEffect, useCallback } from "react";
import ArtData from "../data/art_data";

// Create the context
const LootboxResourcesContext = createContext({
  data: null,
  refreshData: () => {},
});

// Create the provider component
export const LootboxResourcesProvider = ({ children, componentAddress}) => {

  const [data, setData] = useState(null);
  
  const [ticketAddress, setTicketAddress] = useState(null);

  const FEATURED_LOOTBOX = process.env.NEXT_PUBLIC_FEATURED_COMPONENT_ADDRESS;

  const fetchData = useCallback(async (componentAddress) => {
    // Extract the component address from the URL path
    const pathArray = window.location.pathname.split('/');
    const compAddressIndex = pathArray.findIndex(element => element.startsWith('component_'));
    const dynamicComponentAddress = compAddressIndex !== -1 ? pathArray[compAddressIndex] : FEATURED_LOOTBOX;

    sessionStorage.setItem('lootboxComponentAddress', dynamicComponentAddress);

    const response = await fetch('/api/all-lootbox-resources', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        address: dynamicComponentAddress,
      })
    });
    if (response.ok) {
      const result = await response.json();
      setData(result.address); // Ensure this is the correct data you want to set
      const ticketAddress = ArtData.find(box => box.componentAddress === result.address.componentAddress).ticketAddress;
      setTicketAddress(ticketAddress);
    } else {
      // Handle error cases
      const result = '';
      setData(result);
      setTicketAddress('');
      console.error("Error fetching data:", response.statusText);
    }
  }, [componentAddress]); // Add componentAddress to the dependencies array

  // Fetch data when the component is mounted or componentAddress changes
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Provide the data and the fetchData function to the context
  return (
    <LootboxResourcesContext.Provider value={{ data, ticketAddress, refreshData: fetchData }}>
      {children}
    </LootboxResourcesContext.Provider>
  );
};

export default LootboxResourcesContext;
