const ArtData = [
	{
		id: 0,
		bigImage: 'https://bafybeidxm7ncmfrrbjk4fnog4y2kfv3tjnp4chy3gj4dwbmafxwwp5meb4.ipfs.w3s.link/olympiaGold.jpg',
		userImage: '/images/logo_white.jpg',
		name: 'Hug the Eco System - SOLD OUT',
		title: 'Hug the Eco System - SOLD OUT',
		componentAddress: process.env.NEXT_PUBLIC_FEATURED_COMPONENT_ADDRESS,
		ticketAddress: process.env.NEXT_PUBLIC_FEATURED_TICKET_ADDRESS,
		tokenAddress: process.env.NEXT_PUBLIC_XRD, // xrd stokenet token
		tokenIconUrl: "https://assets.radixdlt.com/icons/icon-xrd-32x32.png",
		price: 2
	},
];

export default ArtData;