import {
  RadixDappToolkit,
  RadixNetwork,
  DataRequestBuilder,
} from "@radixdlt/radix-dapp-toolkit";

// Declare a variable to hold the instance of RadixDappToolkit
let rdt = null;
if (typeof window !== "undefined") {
  // Check if the code is running in a browser
  rdt = RadixDappToolkit({
    dAppDefinitionAddress:
      "account_rdx12xs6k8upnklu7awyu5wpgnhm3uq9tdc7peh50kt45j584cl8kqnnw5",
    networkId: RadixNetwork.Stokenet,
    applicationName: "$BOBBY Lootboxes",
    applicationVersion: "1.0.0",
  });

  rdt.walletApi.setRequestData(
    DataRequestBuilder.persona(),
    DataRequestBuilder.accounts().exactly(1)
  );
}

export const rdtInstance = rdt;
export const buyLootboxTicket = async (
  accountAddress,
  tokenAmount,
  componentAddress,
  tokenAddress
) => {
  const manifest = `
    CALL_METHOD
        Address("${accountAddress}")
        "withdraw"
        Address("${tokenAddress}")
        Decimal("${tokenAmount}")
    ;
    TAKE_ALL_FROM_WORKTOP
        Address("${tokenAddress}")
        Bucket("xrd_bucket")
    ;
    CALL_METHOD
        Address("${componentAddress}")
        "buy_loot_box_ticket"
        Bucket("xrd_bucket")
    ;
    CALL_METHOD
        Address("${accountAddress}")
        "deposit_batch"
        Expression("ENTIRE_WORKTOP")
    ;
    `;
  console.log(manifest);
  const result = await rdt.walletApi.sendTransaction({
    transactionManifest: `
    CALL_METHOD
        Address("${accountAddress}")
        "withdraw"
        Address("${tokenAddress}")
        Decimal("${tokenAmount}")
    ;
    TAKE_ALL_FROM_WORKTOP
        Address("${tokenAddress}")
        Bucket("xrd_bucket")
    ;
    CALL_METHOD
        Address("${componentAddress}")
        "buy_loot_box_ticket"
        Bucket("xrd_bucket")
    ;
    CALL_METHOD
        Address("${accountAddress}")
        "deposit_batch"
        Expression("ENTIRE_WORKTOP")
    ;
    `,
  });

  if (result.isErr()) {
    // code to handle the exception
  }

  const transactionIntentHash = result.value.transactionIntentHash;

  return transactionIntentHash;
};

export const revealLootboxTicket = async (
  accountAddress,
  ticketIds, // Changed to an array of ticket IDs
  componentAddress,
  ticketAddress
) => {
  // Convert the array of ticket IDs to the required format
  const ticketIdsString = ticketIds
    .map((id) => `NonFungibleLocalId("${id}")`)
    .join(",\n");

  const result = await rdt.walletApi.sendTransaction({
    transactionManifest: `
    CALL_METHOD
        Address("${accountAddress}")
        "withdraw_non_fungibles"
        Address("${ticketAddress}")
        Array<NonFungibleLocalId>(
            ${ticketIdsString}
        )
    ;
    TAKE_ALL_FROM_WORKTOP
        Address("${ticketAddress}")
        Bucket("ticket_bucket")
    ;
    CALL_METHOD
        Address("${componentAddress}")
        "reveal_ticket"
        Bucket("ticket_bucket")
    ;
    CALL_METHOD
        Address("${accountAddress}")
        "deposit_batch"
        Expression("ENTIRE_WORKTOP")
    ;
    `,
  });


  if (result.isErr()) {
    // code to handle the exception
  }

  const transactionIntentHash = result.value.transactionIntentHash;

  return transactionIntentHash;
};

export const collectLoot = async (
  accountAddress,
  ticketIds, // Changed to an array of ticket IDs
  ticketAddress,
  componentAddress
) => {
  // Convert the array of ticket IDs to the required format
  const ticketIdsString = ticketIds
    .map(id => `NonFungibleLocalId("${id}")`)
    .join(",\n");

  const result = await rdt.walletApi.sendTransaction({
    transactionManifest: `
    CALL_METHOD
        Address("${accountAddress}")
        "withdraw_non_fungibles"
        Address("${ticketAddress}")
        Array<NonFungibleLocalId>(
            ${ticketIdsString}
        )
    ;
    TAKE_ALL_FROM_WORKTOP
        Address("${ticketAddress}")
        Bucket("ticket_bucket")
    ;
    CALL_METHOD
        Address("${componentAddress}")
        "claim_loot"
        Bucket("ticket_bucket")
    ;
    CALL_METHOD
        Address("${accountAddress}")
        "deposit_batch"
        Expression("ENTIRE_WORKTOP")
    ;
    `,
  });

  if (result.isErr()) {
    // code to handle the exception
  }

  const transactionIntentHash = result.value.transactionIntentHash;

  return transactionIntentHash;
};

export const buyLootboxTicketLockFee = async (
  accountAddress,
  tokenAmount,
  componentAddress
) => {
  const result = await rdt.walletApi.sendTransaction({
    transactionManifest: `
    CALL_METHOD
        Address("${accountAddress}")
        "create_proof_of_amount"
        Address("resource_rdx1t45js47zxtau85v0tlyayerzrgfpmguftlfwfr5fxzu42qtu72tnt0")
        Decimal("100")
    ;
    CALL_METHOD
        Address("${accountAddress}")
        "withdraw"
        Address("resource_rdx1t45js47zxtau85v0tlyayerzrgfpmguftlfwfr5fxzu42qtu72tnt0")
        Decimal("${tokenAmount}")
    ;
    TAKE_ALL_FROM_WORKTOP
        Address("resource_rdx1t45js47zxtau85v0tlyayerzrgfpmguftlfwfr5fxzu42qtu72tnt0")
        Bucket("bobby_bucket")
    ;
    CALL_METHOD
        Address("${componentAddress}")
        "buy_loot_box_ticket_lock_fee"
        Bucket("bobby_bucket")
    ;
    CALL_METHOD
        Address("${accountAddress}")
        "deposit_batch"
        Expression("ENTIRE_WORKTOP")
    ;
    `,
  });

  if (result.isErr()) {
    // code to handle the exception
  }

  const transactionIntentHash = result.value.transactionIntentHash;

  return transactionIntentHash;
};

export const revealLootboxTicketLockFee = async (
  accountAddress,
  ticketIds, // Changed to an array of ticket IDs
  componentAddress,
  ticketAddress
) => {
  // Convert the array of ticket IDs to the required format
  const ticketIdsString = ticketIds
    .map((id) => `NonFungibleLocalId("${id}")`)
    .join(",\n");

  const result = await rdt.walletApi.sendTransaction({
    transactionManifest: `
    CALL_METHOD
        Address("${accountAddress}")
        "create_proof_of_amount"
        Address("resource_rdx1t45js47zxtau85v0tlyayerzrgfpmguftlfwfr5fxzu42qtu72tnt0")
        Decimal("100")
    ;
    CALL_METHOD
        Address("${accountAddress}")
        "withdraw_non_fungibles"
        Address("${ticketAddress}")
        Array<NonFungibleLocalId>(
            ${ticketIdsString}
        )
    ;
    TAKE_ALL_FROM_WORKTOP
        Address("${ticketAddress}")
        Bucket("ticket_bucket")
    ;
    CALL_METHOD
        Address("${componentAddress}")
        "reveal_ticket_lock_fee"
        Bucket("ticket_bucket")
    ;
    CALL_METHOD
        Address("${accountAddress}")
        "deposit_batch"
        Expression("ENTIRE_WORKTOP")
    ;
    `,
  });


  if (result.isErr()) {
    // code to handle the exception
  }

  const transactionIntentHash = result.value.transactionIntentHash;

  return transactionIntentHash;
};

export const collectLootLockFee = async (
  accountAddress,
  ticketIds, // Changed to an array of ticket IDs
  ticketAddress,
  componentAddress
) => {
  // Convert the array of ticket IDs to the required format
  const ticketIdsString = ticketIds
    .map(id => `NonFungibleLocalId("${id}")`)
    .join(",\n");

  const result = await rdt.walletApi.sendTransaction({
    transactionManifest: `
    CALL_METHOD
        Address("${accountAddress}")
        "create_proof_of_amount"
        Address("resource_rdx1t45js47zxtau85v0tlyayerzrgfpmguftlfwfr5fxzu42qtu72tnt0")
        Decimal("100")
    ;
    CALL_METHOD
        Address("${accountAddress}")
        "withdraw_non_fungibles"
        Address("${ticketAddress}")
        Array<NonFungibleLocalId>(
            ${ticketIdsString}
        )
    ;
    TAKE_ALL_FROM_WORKTOP
        Address("${ticketAddress}")
        Bucket("ticket_bucket")
    ;
    CALL_METHOD
        Address("${componentAddress}")
        "claim_loot_lock_fee"
        Bucket("ticket_bucket")
    ;
    CALL_METHOD
        Address("${accountAddress}")
        "deposit_batch"
        Expression("ENTIRE_WORKTOP")
    ;
    `,
  });

  if (result.isErr()) {
    // code to handle the exception
  }

  const transactionIntentHash = result.value.transactionIntentHash;

  return transactionIntentHash;
};

