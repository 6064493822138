import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { walletModalhide } from "../../redux/counterSlice";
import { useWalletData } from "../WalletDataContext";
import { revealLootboxTicket, revealLootboxTicketLockFee } from "../../utils/radixDT";
import ArtData from "../../data/art_data";

const Wallet_modal = () => {
  const walletModal = useSelector((state) => state.counter.walletModal);
  const dispatch = useDispatch();
  const { walletData, unrevealedTickets, fetchWalletData } = useWalletData();
  const [selectedTicketIds, setSelectedTicketIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showRevealSuccess, setShowRevealSuccess] = useState(false);

  useEffect(() => {
    if (unrevealedTickets?.address?.length > 0) {
      setSelectedTicketIds([unrevealedTickets.address[0].id]);
    }
  }, [unrevealedTickets]);

  const handleRevealTicket = async () => {
    let tickAddr = ArtData.find(box => box.componentAddress === sessionStorage.getItem('lootboxComponentAddress')).ticketAddress;
    setLoading(true);
    const accountAddress = walletData.accounts[0].address;
    try {
      console.log("Revealing tickets with account address", accountAddress);
      const isBobby100ClubMember = await fetch('/api/bobby-100-club', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ account_address: accountAddress, token_amount: 0 })
      }).then(res => res.json());

      if (isBobby100ClubMember.result) {
        await revealLootboxTicketLockFee(accountAddress, selectedTicketIds, sessionStorage.getItem('lootboxComponentAddress'), tickAddr);
      } else {
        await revealLootboxTicket(walletData.accounts[0].address, selectedTicketIds, sessionStorage.getItem('lootboxComponentAddress'), tickAddr);
      }

      await fetchWalletData();
      setShowRevealSuccess(true);
      if (unrevealedTickets?.address?.length === 0) {
        dispatch(walletModalhide());
      }

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const toggleTicketSelection = (ticketId) => {
    setSelectedTicketIds(prevSelected => {
      if (prevSelected.includes(ticketId)) {
        return prevSelected.filter(id => id !== ticketId);
      } else {
        return [...prevSelected, ticketId];
      }
    });
  };

  return (
    <div>
      {/* <!-- Wallet Modal --> */}
      <div className={walletModal ? "block modal fade show " : "modal fade hidden"}>
        {/* loading spinner */}
        {loading && (
          <div className="fixed top-0 left-0 w-full h-full bg-black z-50 p-8 bg- rounded-lg items-center justify-center flex flex-col">
            <div className="bg-accent-dark dark:accent-accent-light text-white text-xl p-4 font-extrabold  rounded-md shadow">
              <p className="animate-pulse">Check your wallet to complete the transaction</p>
            </div>
          </div>
        )}

        {/* reveal success */}
        {showRevealSuccess && (
          <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 p-8 rounded-lg items-center justify-center flex flex-col ">
            <div className=" flex flex-col items-center justify-center p-8 rounded-md bg-red-400 z-50 backdrop-blur-md bg-white/10 dark:bg-black">
              <h3 className="/80 text-black dark:text-white text-md p-4 font-semibold ">
                Tickets revealed successfully
              </h3>
              {/* button to close */}
              <button
                type="button"
                className="bg-accent-dark text-white px-4 py-2 rounded-md text-sm font-semibold hover:bg-accent-dark/80 transition-all hover:shadow-xl w-1/2"
                onClick={() => {
                  setShowRevealSuccess(false);
                }}
              >
                DONE
              </button>
            </div>
          </div>
        )}

        <div className="modal-dialog max-w-lg w-full container font-display text-xs">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title font-display text-sm" id="walletModalLabel">
                Reveal your tickets
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => dispatch(walletModalhide())}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                </svg>
              </button>
            </div>

            {/* <!-- Body --> */}
            <div className="modal-body p-6 text-center w-full">
              <span className="text-sm font-semibold ">
                Selected Tickets: {selectedTicketIds.join(", ")}
              </span>
              <div className="text-center dark:text-white max-h-64 overflow-auto rounded-md py-4 px-2 w-full">
                {unrevealedTickets?.address?.length > 0 ? (
                  unrevealedTickets?.address?.map((ticket, i) => {
                    return (
                      <div
                        key={i}
                        className={`flex flex-col items-center justify-center space-y-4 py-8 rounded-lg my-4 bg-gradient-to-br from-accent-dark to-gray shadow-md hover:bg-accent-light transition-colors cursor-pointer ${selectedTicketIds.includes(ticket.id) ? "bg-accent-light" : ""}`}
                        onClick={() => toggleTicketSelection(ticket.id)}
                      >
                        <div className="flex flex-col items-center justify-center space-y-4">
                          <div className="flex flex-col items-center justify-center space-y-4 text-lg">
                            Ticket: {ticket.id}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  ''
                )}
              </div>
            </div>
            {/* <!-- end body --> */}

            <div className="modal-footer">
              <div className="flex items-center justify-center space-x-4">
                <button
                  rel="noreferrer noopener"
                  className="bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
                  onClick={handleRevealTicket}
                >
                  Reveal Tickets
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wallet_modal;
