import React, { useEffect } from 'react';
import Link from "next/link";
import { footerMenuList, socialIcons } from "../data/footer_data";
import Image from "next/image";

const Footer = () => {
  useEffect(() => {
    // Create and set up the script element
    const scriptElement = document.createElement('script');
    scriptElement.id = 'XIDARHalloween2024';
    scriptElement.type = 'module';
    scriptElement.src = `https://my.xidar.io/assets/scripts/halloween2024.js?u=${Date.now()}`;
    document.body.appendChild(scriptElement);
    
    return () => {
      // Cleanup the script element on component unmount
      document.body.removeChild(scriptElement);
    };
  }, []);

  return (
    <>
      <footer className="dark:bg-jacarta-700 page-footer bg-white">
        <div className="flex flex-col">
          <div className="flex flex-col w-full px-8 max-h-fit">
            <div className="flex justify-between w-full max-h-fit">
              {/* Logo */}
              <Link href="/" className="hidden dark:inline-block max-h-fit">
                <Image
                  width={75}
                  height={10}
                  src="/images/Bobby_White_Vector.svg"
                  alt="$BOBBY | NFT Lootboxes"
                />
              </Link>
              <Link href="/" className="inline-block dark:hidden max-h-fit">
                <Image
                  width={75}
                  height={10}
                  src="/images/Bobby_Black_Vector.svg"
                  alt="$BOBBY | NFT Lootboxes"
                />
              </Link>
              <div className="dark:text-jacarta-300 flex pt-2 justify-end gap-4 w-full">
                <Link href="https://www.radixdlt.com" className="inline-block max-h-fit" target="_blank">
                  <Image
                    width={125}
                    height={10}
                    src="/images/runsOnRadixLogo.png"
                    alt="Runs on Radix"
                  />
                </Link>
                <div className="flex flex-col justify-center items-center max-w-fit">
                  <Link href="https://xrd.domains" className="inline-block max-h-fit" target="_blank">
                    <p className="text-xs">Domain by</p>
                    <Image
                      width={100}
                      height={10}
                      src="/images/dotRnsLogo.png"
                      alt="XRD Domains"
                    />
                  </Link>
                </div>
              </div>
            </div>

            {/* Socials */}
            <div className="flex space-x-5 px-4">
              {socialIcons.map((item) => {
                const { id, href, text } = item;
                return (
                  <Link
                    href={href}
                    key={id}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="group cursor-pointer"
                  >
                    <svg className="icon group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white">
                      <use xlinkHref={`/icons.svg#icon-${text}`}></use>
                    </svg>
                  </Link>
                );
              })}
            </div>
            {footerMenuList.map((single) => (
              <div
                className={`col-span-full sm:col-span-3 md:col-span-2 ${single.diffClass}`}
                key={single.id}
              >
                <h3 className="font-display text-jacarta-700 mb-6 text-sm dark:text-white">
                  {single.title}
                </h3>
                <ul className="dark:text-jacarta-300 flex flex-col space-y-1">
                  {single.list.map((item) => {
                    const { id, href, text } = item;
                    return (
                      <li key={id}>
                        <Link
                          href={href}
                          className="hover:text-accent dark:hover:text-white"
                        >
                          {text}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ))}
          </div>

          <div className="flex flex-col items-center justify-between space-y-2 py-8 sm:flex-row sm:space-y-0 px-8">
            <span className="dark:text-jacarta-400 text-sm">
              <span>© {new Date().getFullYear()} $BOBBY — Made by Ballers, for Ballers</span>
            </span>

            <ul className="dark:text-jacarta-400 flex flex-wrap space-x-4 text-sm">
              <li>
                <Link
                  href="/terms"
                  className="hover:text-accent dark:hover:text-white"
                >
                  Terms and conditions
                </Link>
              </li>
              <li>
                <Link
                  href="/terms"
                  className="hover:text-accent dark:hover:text-white"
                >
                  Privacy policy
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
