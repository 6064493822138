import Image from "next/image";
import Link from "next/link";
import DarkMode from "../mode/DarkMode";
import Logo from "./../../public/images/Bobby_Black_Vector.svg";
import WhiteLogo from "./../../public/images/Bobby_White_Vector.svg";
import { v4 as uuidv4 } from "uuid";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import WalletButton from "../wallet-btn/WalletButton";
import { buyBobbyModalShow } from "../../redux/counterSlice";
import { useDispatch } from "react-redux";

export default function Header01() {
  const dispatch = useDispatch();

  const [toggle, setToggle] = useState(false);
  const [isCollapse, setCollapse] = useState(null);

  // window resize
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth >= 1024) {
        setToggle(false);
      }
    });
  });

  const route = useRouter();
  /* -------------------------------------------------------------------------- */
  /*                            daynamic navigations                            */
  /* -------------------------------------------------------------------------- */
  const home = {
    id: 1,
    name: "Lootboxes",
    pages: [
      {
        id: uuidv4(),
        name: "Lootbox 1",
        path: "/",
      },
      {
        id: uuidv4(),
        name: "Lootbox 2",
        path: "/home/home_2",
      },
      {
        id: uuidv4(),
        name: "Lootbox 3",
        path: "/home/home_3",
      },
    ],
  };

  const mobileCollapse = (id) => {
    if (isCollapse === id) {
      return setCollapse(null);
    }
    setCollapse(id);
  };

  return <>
    {/* main desktop menu sart*/}
    <header className="js-page-header fixed top-0 z-20 w-full backdrop-blur transition-colors">
      <div className="flex items-center px-6 py-6 xl:px-24 ">
        <Link className="shrink-0" href="/" >

          <div className="dark:hidden">
            <Image
              src={Logo}
              height={80}
              width={80}
              alt="$BOBBY | NFT Lootboxes"
              className="h-auto "
            />
          </div>
          <div className="hidden dark:block">
            <Image
              src={WhiteLogo}
              height={80}
              width={80}
              alt="$BOBBY | NFT Lootboxes"
            />
          </div>

        </Link>
        {/* End  logo */}

        <div className="js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-10 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent">
          <nav className="navbar w-full">
          </nav>
          {/* End menu for desktop */}

          <div className="ml-8 hidden items-center lg:flex xl:ml-12">
            <button className="hover:scale-105 shadow-accent-volume hover:bg-accent-dark inline-block max-w-fit rounded-lg py-3 px-12 text-center font-semibold transition-all w-48 mr-4 z-40"
            onClick={() => dispatch(buyBobbyModalShow())}

            >Buy $BOBBY</button>
            <WalletButton />
            <DarkMode />
          </div>
          {/* End header right content (metamask and other) for desktop */}
        </div>
        {/* header menu conent end for desktop */}

        <div className="ml-auto flex lg:hidden">

          <DarkMode />
          <button
            className="js-mobile-toggle border-jacarta-100 hover:bg-accent dark:hover:bg-accent focus:bg-accent group ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
            aria-label="open mobile menu"
            onClick={() => setToggle(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={24}
              height={24}
              className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M18 18v2H6v-2h12zm3-7v2H3v-2h18zm-3-7v2H6V4h12z" />
            </svg>
          </button>
        </div>
        {/* End header right content  for mobile */}
      </div>
      {/* End flex item */}
    </header>
    {/* main desktop menu end */}

    {/* start mobile menu and it's other materials  */}
    <div
      className={`lg:hidden js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-20 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent ${
        toggle ? "nav-menu--is-open" : "hidden"
      }`}
    >
      <div className="t-0 dark:bg-jacarta-800 fixed left-0 z-10 flex w-full items-center justify-between bg-white p-6 lg:hidden">
        <div className="dark:hidden">
          <Image
            src={Logo}
            height={28}
            width={130}
            alt="$BOBBY | NFT Lootboxes"
            className="max-h-7 h-auto "
          />
        </div>

        <div className="hidden dark:block">
          <Image
            src={WhiteLogo}
            height={28}
            width={130}
            alt="$BOBBY | NFT Lootboxes"
          />
        </div>

        <button
          className="js-mobile-close border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
          onClick={() => setToggle(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={24}
            height={24}
            className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
          </svg>
        </button>
      </div>
      {/* mobile menu top header content */}

      <form action="search" className="relative mt-24 mb-8 w-full lg:hidden">

        <span className="absolute left-0 top-0 flex h-full w-12 items-center justify-center rounded-2xl">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={24}
            height={24}
            className="fill-jacarta-500 h-4 w-4 dark:fill-white"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z" />
          </svg>
        </span>
      </form>
      {/* End search form mobile menu  */}

      <nav className="navbar w-full mt-12">
        <div className="ml-8  items-center flex flex-col gap-4 xl:ml-12">
          <button className="hover:scale-105 shadow-accent-volume hover:bg-accent-dark inline-block max-w-fit rounded-lg py-3 px-12 text-center font-semibold transition-all w-48 mr-4 z-40"
          onClick={() => dispatch(buyBobbyModalShow())}

          >Buy $BOBBY</button>
          <WalletButton />
          <DarkMode />
        </div>
      </nav>
      {/* End navbar mobile menu  */}
      {/* mt-10 w-full lg:hidden */}
    </div>
    {/* End mobile menu and it's other materials */}
  </>;
}
